import { NavigationChild } from 'assets/js/navigation'

export default function useBreadcrumbLabels () {
	const getBreadcrumbs = (obj: NavigationChild, link: string, labelArray: Array<{ label: string, path: string }> = []) => {
		const newLabelArray = [...labelArray, { label: obj?.label, path: obj?.link }]
		return obj?.link === link && !obj?.children?.length
			? newLabelArray.filter(obj => obj?.label)
			: obj?.children?.flatMap(child => getBreadcrumbs(child, link, newLabelArray)) ?? []
	}

	return { getBreadcrumbs }
}
