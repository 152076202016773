import { defineStore } from 'pinia'
import { getArticleBySlug, ContentBlock } from 'ic-js-content'

export interface Partner {
	text: string,
	tier: string,
	type: string,
	imageUrl: string,
	linkUrl: string,
}

export const usePartnersStore = defineStore('partners', () => {
	let sponsors = ref<Array<ContentBlock>>([])
	let partners = ref<Array<ContentBlock>>([])
	let sponsorTiers = ref([])
	let partnerTiers = ref([])

	function getTiers (array: ContentBlock[]) {
		const mappedPartner: Partner[] = array.map((partner: ContentBlock) => {
			// Generic to attend Ulster and the Others
			return <Partner> ({
				text: partner.customContent.logo_content.title,
				tier: partner.customContent.tier_string || partner.customContent.tier_number,
				type: partner.customContent.type_string,
				imageUrl: partner.customContent.logo_content.image,
				linkUrl: partner.customContent.link_string,
			})
		})

		return mappedPartner.reduce((arr: Partner[][], p) => {
			const tier = +p.tier - 1
			arr[tier] ? arr[tier].push(p) : arr[tier] = [p]
			return arr
		}, [] as Partner[][])
	}

	const fetchSponsors = async () => {
		if (!sponsors.value?.length) {
			const fetchedSponsors = await getArticleBySlug('sponsors')

			if (fetchedSponsors) {
				sponsors.value = fetchedSponsors?.data?.article?.content || []
				sponsorTiers.value = getTiers(sponsors.value)
			}
		}
	}

	const fetchPartners = async () => {
		if (!partners.value?.length) {
			const fetchedPartners = await getArticleBySlug('partners')

			if (fetchedPartners) {
				partners.value = fetchedPartners?.data?.article?.content || []
				partnerTiers.value = getTiers(partners.value)
			}
		}
	}

	return {
		sponsorTiers,
		partnerTiers,
		sponsors,
		partners,
		fetchSponsors,
		fetchPartners,
	}
})
