<script setup lang="ts">
const emit = defineEmits(['click'])

const props = defineProps({
	active: { type: Boolean, default: false },
	preselected: { type: Boolean, default: false },
})
</script>

<template>
	<button
		type="button"
		class="
			px-4
			flex
			items-center
			justify-center
			font-flama
			font-bold
			text-sm
			text-mono-50
			tracking-[0.3px]
			whitespace-no-wrap
			focus:outline-none
			border-b-2
			border-b-primary-400
			hover:border-b-2
			hover:border-b-white
			hover:bg-primary-600
    "
		:class="{'bg-primary': active || preselected}"
		@click="emit('click')"
	>
		<slot :isActive="props.active"/>
	</button>
</template>
